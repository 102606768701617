/* eslint-disable @typescript-eslint/naming-convention */
import { categoryToRoute } from '@app-web/common/core/utils/helpers/categoryToRoute';

type Props = {
  history: Record<string, any>;
  location: {
    search: string;
  };
  match: Record<string, any>;
};

type EntityType = 'pr' | 'prt' | 'item' | 'vehicle' | 'dwelling' | 'incident';
type ViewType = 'list' | 'details' | 'add' | 'unsubscribe' | 'create' | 'card';
type ParamsObject = {
  category: string;
  entity: EntityType;
  id?: string;
  prescriptionGroup?: string;
  rx_group?: string;
  view: ViewType;
};

const anonymousByCategory = {
  DR: [
    '/reflink',
    '/dwelling-rental/enrollment/',
    '/dwelling-rental/protection/new/',
    '/dwelling-rental/protections/new/',
  ],
  HOSTING: '/hosting/enrollment/',
  OB: [
    '/reflink',
    '/booth-operation/protections/list',
    '/booth-operation/events/new',
    '/booth-operation/enrollment',
  ],
  PHS: '/providing-household-services/enrollment',
  SB: ['/small-business/protections/new', '/small-business/enrollment'],
};
export const categoriesToAnonymousRoutes = (
  categories: Array<Record<string, any>>,
): Array<string> =>
  categories.reduce(
    (result: Array<any>, category: Record<string, any>): Array<any> => {
      let newResult = result;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const routes = anonymousByCategory[category.id.toUpperCase()];

      if (routes) {
        if (Array.isArray(routes)) {
          // this is in case we want more than one anonymous entry point for a category
          newResult = newResult.concat(routes);
        } else {
          newResult.push(routes);
        }
      }

      return newResult;
    },
    [],
  );
export const categoryIdIsAnonymous = (categoryId: string): boolean =>
  // eslint-disable-next-line no-prototype-builtins
  anonymousByCategory.hasOwnProperty(categoryId.toUpperCase());
export const categoryIsAnonymous = (category: Record<string, any>): boolean =>
  categoryIdIsAnonymous(category.id);
// these are to generate any routes outside of "/easy_estimate" or "/support" routes we want accessible for non authed users
const nonAuthedRoutesByCategory = {};
// need to keep '/easy_estimate' and '/support' as base in order to support existing un authed routes
const baseNonAuthed = ['/easy_estimate', '/support', '/homeshare/enrollment'];
export const categoriesToNonAuthedRoutes = (
  categories: Array<Record<string, any>>,
): Array<string> =>
  categories.reduce(
    (result: Array<any>, category: Record<string, any>): Array<any> => {
      let newResult = result;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const routes = nonAuthedRoutesByCategory[category.id.toUpperCase()];

      if (routes) {
        if (Array.isArray(routes)) {
          // this is in case we want more than one non authed route for a category
          newResult = newResult.concat(routes);
        } else {
          newResult.push(routes);
        }
      }

      return newResult;
    },
    baseNonAuthed,
  );
// any default values here will be assumed if url params are missing
const defaultParams = {
  entity: 'prt',
  view: 'list',
};
// only these keys will be sent to the logic function
// anything NOT one of these keys will be carried over to the resulting path
const paramAllowList = [
  'category',
  'entity',
  'view',
  'rx_group',
  'prescriptionGroup',
  'id',
  'specId',
  'anonymous_user_id',
  'mrtg_ref_id',
  'mktg_ref_id',
];

// appy object to url string using key value
function applyUrlParams(url: string, params: Record<string, any>): string {
  const urlAlreadyHasSearchParams = url.includes('?');
  let result = urlAlreadyHasSearchParams ? `${url}&` : `${url}?`;

  for (const [key, value] of Object.entries(params)) {
    if (key && value && typeof value === 'string') {
      result = `${result}${key}=${value}&`;
    }
  }

  if (
    result.lastIndexOf('&') === result.length - 1 ||
    result.lastIndexOf('?') === result.length - 1
  ) {
    // if there is a trailing "&" or "?" remove it
    result = result.slice(0, -1);
  }

  return result;
}

// all our category specific logic for deep linking
const categoryLogic = {
  DR: (params: ParamsObject): string => {
    const { entity, view, rx_group, id } = params;

    // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    if (entity === 'user') {
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      if (view === 'restore-anonymous' && id) {
        return `${
          categoryToRoute.DR
        }/protection/new?restoreAnonymousUser=${true}&id=${id}`;
      }
    }

    if (entity === 'prt') {
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      if (view === 'new') {
        if (rx_group) {
          return `${categoryToRoute.DR}/protection/new?group=${rx_group}`;
        }
        return `${categoryToRoute.DR}/enrollment`;
      }
    }

    return `${categoryToRoute.DR}`;
  },
  HOSTING: (): string => {
    return `${categoryToRoute.HOSTING}/protections`;
  },
  HS: (params: ParamsObject): string => {
    const { entity, view, id } = params;

    if (view === 'add') {
      return `${categoryToRoute.HS}/properties/new`;
    }

    if (entity === 'prt') {
      if (view === 'list') {
        return `${categoryToRoute.HS}/calendar`;
      }

      if (view === 'details' && id) {
        return `${categoryToRoute.HS}/listings/details/${id}`;
      }
    }

    return `${categoryToRoute.HS}`;
  },
  IS: (params: ParamsObject): string => {
    const { entity, view, id } = params;

    if (entity === 'prt') {
      if (view === 'list') {
        return `${categoryToRoute.IS}/listings`;
      }

      if (view === 'details' && id) {
        return `${categoryToRoute.IS}/listings/details/${id}`;
      }

      if (view === 'add') {
        return `${categoryToRoute.IS}/items`;
      }
    }

    if (entity === 'pr') {
      if (view === 'add') {
        return `${categoryToRoute.IS}/listings`;
      }
    }

    if (entity === 'item') {
      // only really 1 view for items
      return `${categoryToRoute.IS}/items`;
    }

    return `${categoryToRoute.IS}`;
  },
  OB: (params: ParamsObject): string => {
    const { entity, view, prescriptionGroup } = params;

    if (entity === 'prt') {
      // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      if (view === 'new') {
        if (prescriptionGroup) {
          return `${categoryToRoute.OB}/protections/list?prescriptionGroup=${prescriptionGroup}`;
        }
        return `${categoryToRoute.OB}/enrollment`;
      }
    }

    return `${categoryToRoute.OB}/protections`;
  },
  PHS: (): string => {
    return `${categoryToRoute.PHS}/protections`;
  },
  SB: (): string => {
    return `${categoryToRoute.SB}/protections`;
  },
};

const getDeepLinkRedirectUrl = (
  params: Record<string, any>,
  category: string,
  config: Record<string, any>,
  getRedirectAnonToRegistrationUrl: (...args: Array<any>) => any,
): string => {
  const { entity, view, id, specId } = params;

  // Unsubscribe from marketing
  if (view === 'unsubscribe') {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return `${categoryToRoute[category]}/unsubscribe`;
  }

  // Claims
  if (entity === 'incident') {
    if (view === 'create') {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return `${categoryToRoute[category]}/claims`;
    }
  }

  // Credit card screen
  if (view === 'card' || entity === 'card') {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return `${categoryToRoute[category]}/profile`;
  }

  // Protections
  if (entity === 'pr') {
    // Dashboard
    if (view === 'list') {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return `${categoryToRoute[category]}/protections`;
    }

    // Details
    if (view === 'details' && id) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return `${categoryToRoute[category]}/protections/details/${id}`;
    }

    if (view === 'coverage' && id) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return `${categoryToRoute[category]}/protections/details/${id}/coverages`;
    }
  }

  if (entity === 'user') {
    // Returns redirectAnonToRegistration link with link-anonymous URL as reflink redirect_uri
    if (view === 'link-anonymous-register' && id && id !== '') {
      let redirectUrl = `/reflink?entity=user&view=link-anonymous&id=${id}`;
      if (specId) redirectUrl += `&specId=${specId}`;
      return getRedirectAnonToRegistrationUrl(redirectUrl);
    }
  }

  // Fall back to category specific logic
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return categoryLogic[category](params, config);
};

export const deeplinking =
  (
    config: Record<string, any>,
    getRedirectAnonToRegistrationUrl: (...args: Array<any>) => any,
  ): any =>
  (RouteProps: Props): any => {
    const {
      location: { search },
      history: { push },
    } = RouteProps;
    const linkingParams = new URLSearchParams(search);
    // pull out all whitelisted params from the URLSearchParams
    // use reduce to an object so we can overwrite default values
    const linkingValues = paramAllowList.reduce(
      (result: Record<string, any>, paramKey: string): Record<string, any> => {
        const newResult = { ...result };

        if (linkingParams.has(paramKey)) {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          newResult[paramKey] = linkingParams.get(paramKey).toLowerCase();
        }

        return newResult;
      },
      {},
    );
    // get any values from search params that are NOT on the whitelist so we can continue to pass those through
    const passThroughValues = Array.from(linkingParams.keys())
      .filter((key: string): boolean => !paramAllowList.includes(key))
      .reduce(
        (
          result: Record<string, any>,
          paramKey: string,
        ): Record<string, any> => ({
          ...result,
          [paramKey]: linkingParams.get(paramKey),
        }),
        {},
      );
    // assign all params using default values if needed
    const category =
      linkingParams.get('category') || config.SLICE_DEFAULT_CATEGORY;
    const params = { ...defaultParams, ...linkingValues };

    // if category logic exists then use that
    // eslint-disable-next-line no-prototype-builtins
    if (categoryLogic.hasOwnProperty(category.toUpperCase())) {
      const redirect = applyUrlParams(
        getDeepLinkRedirectUrl(
          params,
          category.toUpperCase(),
          config,
          getRedirectAnonToRegistrationUrl,
        ),
        passThroughValues,
      );

      // TODO: Look into if this can be fully removed or not
      // Adds state to history call so that HBBO is aware that
      // the incoming user has been linked to from an anonymous user
      if (
        params &&
        params.entity === 'user' &&
        params.view === 'link-anonymous'
      ) {
        push({
          pathname: redirect,
          state: {
            linkedFromAnonymousUser: true,
          },
        });
      } else {
        window.location.replace(redirect);
      }

      return null;
    }

    // otherwise just go to emtpy route and let the app fallback urls handle it
    push(applyUrlParams('/', passThroughValues));
    // we need to return null because this is technically a render function
    return null;
  };
