/* eslint-disable react/display-name */
import { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
  authenticate,
  register,
  createAnonymousUser,
  createRestoredAnonymousUser,
} from '@app-web/common/core/utils/auth';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Loadable from 'react-loadable';
import { pathOr } from 'ramda';
import ConfigProvider from '@app-web/common/core/utils/providers/ConfigProvider';
import {
  isUserAnonymous,
  cleanAuthData,
  getRedirectAnonToRegistrationUrl,
} from '@app-web/common/core/utils/providers/AuthProvider/utils';
import GoogleAnalytics from '@app-web/common/core/utils/misc/GoogleAnalytics';
import cookies from '@app-web/common/core/utils/storage/cookies';
import numeral from 'numeral';
import { resolveEntryData } from '@app-web/common/core/utils/misc/entryResolver';
import {
  deeplinking,
  categoriesToAnonymousRoutes,
  categoriesToNonAuthedRoutes,
  categoryIsAnonymous,
} from './routingLogic';

type RootProps = {
  agents: Array<Record<string, any>>;
  categories: Array<Record<string, any>>;
  config: Record<string, any>;
};
const AgentNotFoundDialog = Loadable({
  loading: () => null,
  loader: (): Promise<any> =>
    import(
      /* webpackChunkName: "agent-not-found-dialog" */
      '@app-web/common/core/utils/providers/AgentProvider/AgentNotFoundDialog'
    ),
});
const LoggedOutRoot = Loadable({
  loading: () => null,
  loader: (): Promise<any> =>
    import(
      /* webpackChunkName: "logged-out-root" */
      './LoggedOutRoot'
    ),
});
const LoggedInRoot = Loadable({
  loading: () => null,
  loader: (): Promise<any> =>
    import(
      /* webpackChunkName: "logged-in-root" */
      './LoggedInRoot'
    ),
});

const App = ({
  agents,
  categories,
  config,
}: RootProps): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const [state, setState] = useState({
    agent: undefined,
    category: undefined,
    auth: undefined,
    locale: undefined,
    isAnonymous: undefined,
  });
  useEffect(() => {
    const caller = async () => {
      const { agent, category, locale } = resolveEntryData(
        config,
        agents,
        categories,
      );
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 4-5 arguments, but got 3.
      const authResult = await authenticate(config, agent, category);
      const isAnonymous =
        authResult &&
        authResult.accessToken &&
        isUserAnonymous(authResult.accessToken);
      setState({
        // @ts-expect-error ts-migrate(2332) FIXME:
        agent,
        category,
        auth: authResult,
        isAnonymous,
        locale,
      });
      // Store query parameters that exist when first directed to application
      // Categories can make use of this as they see fit.
      cookies.write('incomingQueryParameters', window.location.search);
    };

    caller();
  }, []);

  const handleRegister = () => {
    const { agent, category, locale } = state;

    if (agent === undefined || category === undefined) {
      return null;
    }

    register(config, agent, category, locale);
    return null;
  };

  const handleAuthenticate = () => {
    const { agent, category, locale } = state;

    if (agent === undefined || category === undefined) {
      return null;
    }

    authenticate(config, agent, category, locale, true);
    return null;
  };

  const handleAnonymousUser = () => {
    const { agent, category, locale } = state;

    if (agent === undefined || category === undefined || category === null) {
      return null;
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Location' is not assignable to p... Remove this comment to see the full error message
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    const entity = searchParams.get('entity');
    const view = searchParams.get('view');
    const id = searchParams.get('id');
    const isRestoringAnonymousUser = searchParams.get('restoreAnonymousUser');

    // Calls new anonymous user endpoint
    // This restores the user on the backend
    if (
      ((entity === 'user' && view === 'restore-anonymous') ||
        isRestoringAnonymousUser) &&
      id !== ''
    ) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      createRestoredAnonymousUser(config, agent, url, id, category, locale);
    } else {
      createAnonymousUser(config, agent, url, category, locale);
    }

    return null;
  };

  const isReflink = (): boolean => {
    return (
      typeof window.location.pathname === 'string' &&
      window.location.pathname.includes('/reflink')
    );
  };

  const renderLogoutAnonUser = (route: Record<string, any>) => {
    cleanAuthData();
    const pathname = pathOr('', ['location', 'pathname'], route).replace(
      /\\|\//g,
      '',
    );

    if (pathname === 'signup') {
      return handleRegister();
    }

    return handleAuthenticate();
  };

  const commonProps = (
    routeProps: Record<string, any>,
  ): Record<string, any> => {
    const { agent, category, locale, auth } = state;
    return { ...routeProps, auth, config, agent, category, locale };
  };

  const renderLoggedOut =
    (categories: Array<Record<string, any>>) =>
    // eslint-disable-next-line react/no-unstable-nested-components
    (routeProps: Record<string, any>) => {
      cleanAuthData();
      return (
        <LoggedOutRoot
          handleAuthenticate={handleAuthenticate}
          categoryList={categories}
          {...commonProps(routeProps)}
        />
      );
    };

  const renderLoggedIn =
    (categories: Array<Record<string, any>>) =>
    // eslint-disable-next-line react/no-unstable-nested-components
    (routeProps: Record<string, any>) => {
      const { isAnonymous } = state;
      return (
        <LoggedInRoot
          {...commonProps(routeProps)}
          categoryList={categories}
          isAnonymous={isAnonymous}
        />
      );
    };

  const renderReflink = (): React.ReactElement<
    React.ComponentProps<any>,
    any
  > => {
    const { agent, auth } = state;

    const getRedirectAnonRegistrationUrlFunc =
      (
        // @ts-expect-error ts-migrate(6133) FIXME: 'redirectUrl' is declared but its value is never r... Remove this comment to see the full error message
        redirectUrl: string,
        agent: Record<string, any>,
        config: Record<string, any>,
        clientId: string,
        auth: Record<string, any>,
      ) =>
      (redirectUrl: string) =>
        getRedirectAnonToRegistrationUrl(
          redirectUrl,
          agent,
          config,
          clientId,
          auth,
        );

    let panNum = '';

    if (agent) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      panNum = agent.panNum;
    }

    const pan = `pan${numeral(panNum).format('00')}`;
    const clientId = `${pan}-${config.SLICE_OPERATING_COUNTRY}-web-app-client`;
    return (
      <Route
        key="deeplink"
        path="/reflink"
        render={deeplinking(
          config,
          getRedirectAnonRegistrationUrlFunc(
            'redirectUrl',
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
            agent,
            config,
            clientId,
            auth,
          ),
        )}
      />
    );
  };

  const renderRoutes = (isAuth: boolean): Array<any> => {
    const { isAnonymous } = state;
    // Translate reflink before performing routing logic and rendering app routes
    let refLink = null;

    if (isReflink()) {
      refLink = renderReflink();
    }

    if (!isAuth) {
      // generate our available anonymous routes from our available categories
      const anonymousRoutesArray = categoriesToAnonymousRoutes(categories);
      let anonymousRoutes = null;

      if (anonymousRoutesArray.length > 0) {
        // only want to render a route if we have any anonymous routes to support
        anonymousRoutes = (
          <Route
            key="anonymousEntry"
            path={anonymousRoutesArray}
            render={handleAnonymousUser}
            exact
          />
        );
      }

      return [
        refLink,
        <Route key="signup" path="/signup" render={handleRegister} />,
        <Route
          key="loggedout"
          path={categoriesToNonAuthedRoutes(categories)}
          render={renderLoggedOut(categories)}
        />,
        anonymousRoutes,
        <Route key="authenticate" render={handleAuthenticate} />,
      ];
    }

    if (isAnonymous) {
      const anonymousCategories = categories.filter(categoryIsAnonymous);
      return [
        refLink,
        <Route key="login" path="/login" render={renderLogoutAnonUser} />,
        <Route key="login" path="/signup" render={renderLogoutAnonUser} />,
        <Route
          key="loggedout"
          path={categoriesToNonAuthedRoutes(categories)}
          render={renderLoggedOut(categories)}
        />,
        <Route key="loggedin" render={renderLoggedIn(anonymousCategories)} />,
      ];
    }

    return [
      refLink,
      <Route key="loggedin" render={renderLoggedIn(categories)} />,
    ];
  };

  const { agent, category, auth, isAnonymous } = state;

  if (!Array.isArray(agents) || agents.length === 0) {
    return <AgentNotFoundDialog />;
  }

  if (agent === undefined || category === undefined || auth === undefined) {
    return null;
  }

  if (category === null && isAnonymous) {
    cleanAuthData();
  }

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const isAuth = auth && auth.accessToken;
  return <Switch>{renderRoutes(isAuth)}</Switch>;
};

const Root = ({
  config,
  agents,
  categories,
}: RootProps): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    // @ts-expect-error ts-migrate(2740) FIXME: Type 'Record<string, any>' is missing the followin... Remove this comment to see the full error message
    <ConfigProvider config={config}>
      <GoogleAnalytics config={config} />
      <BrowserRouter>
        <App config={config} agents={agents} categories={categories} />
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default Root;
