import ReactDOM from 'react-dom';
import serviceWorkerRegistration from '@app-web/common/core/utils/misc/serviceWorkerRegistration';
import { categoryData, agentData } from '@app-web/common/core/utils/misc/entry';
import { hostingBounceRedirectPath } from '@app-web/common/core/utils/misc/constants';
import MaintenanceRoot from '@app-web/common/core/platform/MaintenanceRoot';
import App from './AppRoot';

// Bounce users attempting to access these links to the partner category site
const hostingBouncePaths = ['/hosting/enrollment', '/hosting/protections/new'];

const anyBoucePathsMet = (bouncePaths: Array<string>) => {
  return bouncePaths.some(
    (path) => window.location.pathname.indexOf(path) > -1,
  );
};

serviceWorkerRegistration();
const el = document.getElementById('root');

(async () => {
  // Bounce users attempting to access HOSTING flow
  if (window && anyBoucePathsMet(hostingBouncePaths)) {
    window.location.replace(hostingBounceRedirectPath);
  }

  try {
    const dateFormat = new Intl.DateTimeFormat();
    dateFormat.formatToParts(new Date());
  } catch (err) {
    await import(
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'date... Remove this comment to see the full error message
      'date-time-format-timezone/build/src/date-time-format-timezone-no-data'
    );
    const loaders = await Promise.all([
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'date... Remove this comment to see the full error message
      import('date-time-format-timezone/build/src/data/tzdata'),
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'date... Remove this comment to see the full error message
      import('date-time-format-timezone/build/src/data/metazone'),
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'date... Remove this comment to see the full error message
      import('date-time-format-timezone/build/src/data/locales/locale-en-GB'),
      import(
        // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'date... Remove this comment to see the full error message
        'date-time-format-timezone/build/src/data/locales/locale-en-US-POSIX'
      ),
      // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'date... Remove this comment to see the full error message
      import('date-time-format-timezone/build/src/data/locales/locale-en-CA'), // import('date-time-format-timezone/build/src/data/locale'),
    ]);
    loaders.forEach((loader) => {
      loader.default(window);
    });
  }

  const catalogData = (
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'config' implicitly has an 'any' type.
    config, // fetch both agent and category data from catalog at the same time
  ) =>
    Promise.all([agentData(config, window), categoryData(config, window)]).then(
      (
        result, // parse results from json
      ) => Promise.all(result.map((v) => v.json())),
    );

  const envFetchOptions = {
    method: 'GET',
    headers: {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
  };

  // First requirement of app is to load env-vars file
  fetch('/config/env-vars.json', envFetchOptions)
    .then((e) => e.json())
    .then((config) => {
      // Check if in maintenance mode, if so, render maintenance page
      if (config.OPS_ENV_STATUS === 'maintenance') {
        ReactDOM.render(<MaintenanceRoot config={config} />, el);
        return;
      }

      // once we have env-vars we want to fetch all possible agents and categories from catalog
      // these will drive the entry point logic, send the user to the proper category with the proper agent
      // use fetch here instead of Apollo Graphql client because apollo dependencies are too large, want to have entry bundle as small as possible
      catalogData(config)
        .then((catalogValues) => {
          const { agents } = catalogValues[0];
          const { categories } = catalogValues[1];
          // only after we have config + agents + categories should we try to mount our App onto DOM
          // avoids the guaranteed rerenders that happen if this logic is done in React components on mount
          ReactDOM.render(
            <App config={config} agents={agents} categories={categories} />,
            el,
          );
        })
        .catch((error) => {
          console.error('Error loading catalog', error);
        });
    })
    .catch((error) => {
      console.error('Error loading config', error);
    });
})();
